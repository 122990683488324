import React, { useEffect, useState } from 'react'
import { FiChevronDown,FiArrowRight } from 'react-icons/fi'
import {Link} from "react-router-dom";
import  $ from 'jquery';
import { authenticationService } from './../../auth/loginAuth'
import HeaderStyle from './../../assets/css/Header.module.css'

export default function Navbar(props) {
    const [navOpen, setNavOpen] = useState(false)
    const [categoriesToggle, toggleMenu] = useState(false)

    const toggleCategories = (e) => {
        e.preventDefault()
        toggleMenu(!categoriesToggle)
    }

    $(document).on('click', '.side-menu-ul li', function () {
        $(".side-menu-ul li").removeClass('active');
        $(this).toggleClass("active")
    })
    $(document).on('click', '.side-menu-ul li.active', function () {
        $(".side-menu-ul li.active").removeClass('active');
    })

    return (
        <>
            <div className="main-menu-content">
                <nav>
                    <ul>
                        {
                            props.mainCategoriesList.map( (e,i) => (
                                <li>
                                    <Link to="#">{e.name}</Link>
                                    {/* { (e.subs) && e.subs.map( (x,s) => (
                                        x.name
                                    ) ) } */}
                                </li>
                            ) )
                        }

                        <li>
                            <Link to="#" onClick={toggleCategories.bind(this)}> Classified <FiChevronDown /></Link>
                            
                            <ul className={`${HeaderStyle.largeDropDown} ` + (categoriesToggle ? HeaderStyle.active : '')}>
                            {
                                props.categoriesList.slice(0,8).map( (e,i) => {
                                    return (
                                    <div className={HeaderStyle.item}>
                                    <h1> <Link to="#"> {e.name} </Link> </h1>
                                    {
                                        e.subs &&
                                        e.subs.slice(0,4).map( (x,s) => {
                                            return (
                                                <li><Link to="#"> {x.name} </Link></li>
                                            )
                                        } )
                                    }
                                    <li className={HeaderStyle.round}> <Link to="#"> View all <FiArrowRight /> </Link> </li>
                                </div>
                                )
                                } )
                            }
                            </ul>

                            {/* <ul className="dropdown-menu-item">
                            { props.categoriesList.slice(0,5).map( (e,i) => (
                                <li>
                                    <Link to="#">{e.name}</Link>
                                </li>
                            ) ) }
                            <li>
                                <Link to="#"> <b>All categories</b> </Link>
                            </li>
                                <li><Link to="/list-map-view2">Electronics</Link></li>
                                <li><Link to="/list-map-view2">Real estate</Link></li>
                                <li><Link to="/all-categories">all categories</Link></li>
                                <li><Link to="/all-locations">all locations</Link></li>
                                <li><Link to="/top-place">top places </Link></li>
                            </ul> */}
                        </li>
                        {/* <li>
                            <Link to="/user-profile">pages <FiChevronDown /></Link>
                            <ul className="dropdown-menu-item">
                                <li><Link to="/user-profile">user profile</Link></li>
                                <li><Link to="/top-author">top authors </Link></li>
                                <li><Link to="/dashboard">dashboard</Link></li>
                                <li><Link to="/booking">booking  </Link></li>
                                <li><Link to="/booking-confirmation">booking confirmation </Link></li>
                                <li><Link to="/invoice">invoice</Link></li>
                                <li><Link to="/pricing">pricing</Link></li>
                            </ul>
                        </li> */}

                        <li>
                            <Link to="#">Help <FiChevronDown /></Link>
                            <ul className="dropdown-menu-item">
                                <li><Link to="/faq">faq</Link></li>
                                <li><Link to="/contact">contact</Link></li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/blog-full-width">Blog</Link>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className="side-menu-open" onClick={() => setNavOpen(!navOpen)}>
                <span className="menu__bar"></span>
                <span className="menu__bar"></span>
                <span className="menu__bar"></span>
            </div>
            <div className={navOpen ? 'side-nav-container active' : 'side-nav-container'}>
                <div className="humburger-menu">
                    <div className="humburger-menu-lines side-menu-close" onClick={() => setNavOpen(!navOpen)}></div>
                </div>
                <div className="side-menu-wrap">
                    <ul className="side-menu-ul">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/all-categories">categories</Link> <span className="la-angle-down"><FiChevronDown /></span>
                            <ul className="dropdown-menu-item">
                                <li><Link to="/all-categories">all categories</Link></li>
                                <li><Link to="/all-locations">all locations</Link></li>
                                <li><Link to="/top-place">top places </Link></li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/listing-grid">listings</Link> <span className="la-angle-down"><FiChevronDown /></span>
                            <ul className="dropdown-menu-item">
                                <li><Link to="/listing-grid">listing grid</Link></li>
                                <li><Link to="/list-map-view">map view  </Link></li>
                                <li><Link to="/list-map-view2">map view 2  </Link></li>
                                <li><Link to="/listing-list">listing list  </Link></li>
                                <li><Link to="/list-left-sidebar">left sidebar  </Link></li>
                                <li><Link to="/list-right-sidebar">right sidebar </Link></li>
                                <li><Link to="/listing-details">listing details</Link></li>
                                <li><Link to="/add-listing">add listing</Link></li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/user-profile">pages</Link> <span className="la-angle-down"><FiChevronDown /></span>
                            <ul className="dropdown-menu-item">
                                <li><Link to="/user-profile">user profile</Link></li>
                                <li><Link to="/top-author">top authors </Link></li>
                                <li><Link to="/dashboard">dashboard</Link></li>
                                <li><Link to="/booking">booking  </Link></li>
                                <li><Link to="/booking-confirmation">booking confirmation </Link></li>
                                <li><Link to="/invoice">invoice</Link></li>
                                <li><Link to="/pricing">pricing</Link></li>
                                <li><Link to="/about">about</Link></li>
                                <li><Link to="/faq">faq</Link></li>
                                <li><Link to="/contact">contact</Link></li>
                                <li><Link to="/page-404">404 page</Link></li>
                                <li><Link to="/recover">recover pass</Link></li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/blog-full-width">blog</Link> <span className="la-angle-down"><FiChevronDown /></span>
                            <ul className="dropdown-menu-item">
                                <li><Link to="/blog-full-width">full width </Link></li>
                                <li><Link to="/blog-grid">blog grid</Link></li>
                                <li><Link to="/blog-left-sidebar">left sidebar </Link></li>
                                <li><Link to="/blog-right-sidebar">right sidebar </Link></li>
                                <li><Link to="/blog-single">blog detail</Link></li>
                            </ul>
                        </li>
                    </ul>
                    <div className="side-nav-button">
                        { !props.loginStatus &&
                            <>
                            <Link to="#" onClick={props.openBox.bind(this)} page="login" className="theme-btn">Login</Link>
                            <Link to="#" onClick={props.openBox.bind(this)} page="signup" className="theme-btn">Sign up</Link>
                            </>
                        }

                        { props.loginStatus &&
                            <>
                            <Link to="#" onClick={authenticationService.logout.bind(this)} className="theme-btn">Logout</Link>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
