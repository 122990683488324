import React, {useState, useEffect} from 'react'
import HeaderStyle from './../../../assets/css/Header.module.css'
import { HiArrowNarrowLeft } from 'react-icons/hi'

import FirstStep from './recoverSteps/FirstStep'
import SecondStep from './recoverSteps/SecondStep'
import ThirdStep from './recoverSteps/ThirdStep'
import FourthStep from './recoverSteps/FourthStep'

export default function RecoveryForm(props) {

    const [buttonDisabled, disableButton] = useState(false);
    const [errorMessage, setError] = useState(false);
    const [inputDisabled, disableInput] = useState(false);

    const [recoverySteps, changeStep] = useState(1);

    const [recoveryState, setRecoverState] = useState({
        userEmail: "",
        userCode: "",
        newPassword: "",
        newPasswordConfirmation: ""
    })
    
    // Handle fields change
    const handleChange = e => {
        let inputName = e.target.name;
        let inputValue = e.target.value;

        setRecoverState ({
            ...recoveryState,
            [inputName]:inputValue
        })
    }

    // Add Error
    const addError = message => {
        setError(message)
    }

    // Remove Error
    const removeError = e => {
        setError(false)
    }

    // Previous step
    const previousStep = e => {
        let prev_step = recoverySteps-1;
        changeStep(prev_step);
    }

    // Next step
    const nextStep = e => {
        let next_step = recoverySteps+1;
        changeStep(next_step);
    }

    return (
        <div id={HeaderStyle.loginContainer}>

            <form action="">
                <div className={HeaderStyle.linksWrapper}>
                    <a href="#" onClick={props.openLoginForm.bind(this)}> <HiArrowNarrowLeft /> Back to login </a>
                </div>

                { recoverySteps === 1 && <FirstStep inputDisabled={inputDisabled} disableInput={disableInput} openLoginForm={props.openLoginForm} removeError={removeError} addError={addError} nextStep={nextStep} errorMessage={errorMessage} state={recoveryState} handleChange={handleChange} disableButton={disableButton} buttonDisabled={buttonDisabled} />}
                { recoverySteps === 2 && <SecondStep inputDisabled={inputDisabled} disableInput={disableInput} openLoginForm={props.openLoginForm} removeError={removeError} addError={addError} previousStep={previousStep} nextStep={nextStep} errorMessage={errorMessage} state={recoveryState} handleChange={handleChange} disableButton={disableButton} buttonDisabled={buttonDisabled} />}
                { recoverySteps === 3 && <ThirdStep inputDisabled={inputDisabled} disableInput={disableInput} openLoginForm={props.openLoginForm} removeError={removeError} addError={addError} state={recoveryState} nextStep={nextStep} errorMessage={errorMessage} handleChange={handleChange} disableButton={disableButton} buttonDisabled={buttonDisabled} />}
                { recoverySteps === 4 && <FourthStep openLoginForm={props.openLoginForm} removeError={removeError} state={recoveryState} errorMessage={errorMessage} />}
            </form>

            {/* <div className={HeaderStyle.switchContainer}>
                <h5> Alreay have an account ? </h5>
                <a href="#"> Login </a>
            </div> */}
        </div>
    )
}
