import React from 'react'

export default {
    verifyRequired: (value) => {
        if (  value.trim().length < 1  )
        {
            return false
        }else{
            return true
        }
    },

    verifyEmail: (email) => {
        if (
            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              email,
            )
          ) {
            return true;
          }
          if (email.trim() === '') {
            return false;
          }
          return false;
    },

    verifyPhone: (phone) => {
        var pattern = new RegExp(/^[0-9\b]+$/);
        
        let isValid = true;

        if (!pattern.test(phone)) {
            isValid = false;
        }else if( phone.length < 8 || phone.length > 15 ){
            isValid = false;
        }

        return isValid;
    },

    verifyPassword: (password) => {
        let isValid = true;
        if ( password.length < 6 )
        {
            isValid = false;
        }
        return isValid
    }
}
