export const mainCategoriesList = 
[
    {
        name: "Jobs",
        subs: [
          {
            name: "Type",
            subs: [
              { name: "Job offers (recruiters) " },
              { name: "Looking for job" }
            ]
          },
          {
            name: "Category",
            subs: [
              { name: "Accounting" },
              { name: "Architecture & Interior design" },
              { name: "Automotive" },
              { name: "Beauty" },
              { name: "Business Development" },
              { name: "Construction" },
              { name: "Customer Service" },
              { name: "Education" },
              { name: "Engineering" },
              { name: "Fashion" },
              { name: "Food & Baverages" },
              { name: "Graphic Design" },
              { name: "Hospitality & Restaurants" },
              { name: "HR & Recruitment" },
              { name: "Industrial & Manufacturing" },
              { name: "Information Technology" },
              { name: "Internet" },
              { name: "Legal Services" },
              { name: "Logistics & Distribution" },
              { name: "Marketing & Advertising" },
              { name: "Media" },
              { name: "Medical & Healthcare" },
              { name: "Online Media" },
              { name: "Real Estate" },
              { name: "Retail & Consumer Goods" },
              { name: "Safety & Security" },
              { name: "Sales" },
              { name: "Secretarial" },
              { name: "Sports & Fitness" },
              { name: "Transportation" },
              { name: "Travel & Tourism" },
            ]
          }
        ]
    },
    {
        name: "Insurance",
        subs: [
          { name: "Car Insurance" },
          { name: "Home Insurance" },
          { name: "Life Insurance" },
          { name: "Disability Insurance" },
          { name: "Health Insurance" },
          { name: "Liability Insurance" },        
        ]
    },
    {
        name: "Electronics",
        subs: [
          { name: "Home Audio & Turntables" },
          { name: "Televisions" },
          { name: "DVD & Home Theater" },
          { name: "Electronic Accessories" },
          { name: "Gadgets" },
          { name: "Car Electronics" },
          { name: "Mp3 Players and Portable Audio" },
          { name: "Satellite & Cable TV" },
          { name: "Health Electronics" },
          { name: "Smart Home" },
          { name: "Wearable Technology" },
          { name: "Other" }
        ]
    },
    {
        name: "Mobiles & Tablets",
        subs: [
          {
            name: "Mobile Phones",
            subs: [
              {
                name: "Brand",
                subs: [
                  { name: "Apple" },
                  { name: "Samsung" },
                  { name: "Google" },
                  { name: "Huawei" },
                  { name: "Acer" },
                  { name: "Alcatel" },
                  { name: "Asus" },
                  { name: "Blackberry" },
                  { name: "CAT" },
                  { name: "CECT" },
                  { name: "HTC" },
                  { name: "Hewlett Packard" },
                  { name: "LG" },
                  { name: "Lava" },
                  { name: "Lenovo" },
                  { name: "Meizu" },
                  { name: "Motorola" },
                  { name: "Nokia" },
                  { name: "OnePlus" },
                  { name: "Oppo" },
                  { name: "Palm" }
                ]
              }
            ]
          },
          {
            name: "Tablets",
            subs: [
              {
                name: "Brand",
                subs: [
                  { name: "Apple" },
                  { name: "Dell" },
                  { name: "Franklin" },
                  { name: "HP/Compaq" },
                  { name: "HTC" },
                  { name: "Handspring" },
                  { name: "Huawei" },
                  { name: "Lenovo" },
                  { name: "Palm" },
                  { name: "RIM" },
                  { name: "Samsung" },
                  { name: "Sharp" },
                  { name: "Sony" },
                  { name: "Symbol" },
                  { name: "Wintouch" },
                  { name: "iMate" }
                ]
              }
            ]
          },
          {
            name: "Mobile phone & Tablets Accessories",
            subs: [
              { name: "Batteries" },
              { name: "Cables & Adapters" },
              { name: "Car Kits" },
              { name: "Cases & Sleeves" },
              { name: "Chargers" },
              { name: "Gimbals & Selfie Sticks" },
              { name: "Headsets" },
              { name: "Other" }
            ]
          }
        ]
      }
]