import React, { Component } from 'react'
import Navbar from './Navbar'
import HeaderAuthorAccess from '../other/account/HeaderAuthorAccess'
import Logo from './Logo'
import $ from 'jquery';
import LoginBox from './../common/LoginBox'

export default class GeneralHeader extends Component {
    componentDidMount() {
        $(window).on('scroll', function () {
            //header fixed animation and control
            if ($(window).scrollTop() > 10) {
                $('.header-menu-wrapper').addClass('header-fixed');
            } else {
                $('.header-menu-wrapper').removeClass('header-fixed');
            }
        });
    }

    state = {
        logo: require('../../assets/images/logo.png'),
        loginBox: false,
        loginPage: "login"
    }

    openBox = (e) =>
    {
        e.preventDefault()
        this.props.toggleDisable(true)
        this.setState({ loginBox: true })
        this.setState({ loginPage: e.target.attributes.page.value })
    }

    closeBox = (e) =>
    {
        e.preventDefault()
        this.setState({ loginBox: false })
        this.props.toggleDisable(false)
    }

    render() {
        return (
            <>
            { this.state.loginBox && <LoginBox closeBox={this.closeBox} loginPage={this.state.loginPage} /> }

                <header className="header-area" style={{background:"#333f578f"}}>
                    <div className="header-menu-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="menu-full-width">
                                        {/* Logo */}
                                        <div className="logo">
                                            <Logo url={this.state.logo} />
                                        </div>

                                        {/* Navbar */}
                                        <Navbar openBox={this.openBox} loginStatus={this.props.loginStatus} categoriesList={this.props.categoriesList} mainCategoriesList={this.props.mainCategoriesList} />

                                        {/* Author Access */}
                                        <HeaderAuthorAccess openBox={this.openBox} loginStatus={this.props.loginStatus} toggleDisable={this.props.toggleDisable} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </>
        )
    }
}
