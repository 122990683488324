import React, {Component} from 'react'
import  { FiSearch } from 'react-icons/fi'

export default class SearchAll extends Component {    
    render() {
        return (
            <>
                <div className="main-search-input">

                    <div className="main-search-input-item">
                        <div className="contact-form-action">
                            <form action="#">
                                <div className="form-group mb-0">
                                <span className="form-icon">
                                    <FiSearch/>
                                </span>
                                    <input className="form-control" type="text"
                                           placeholder="What are you looking for?"/>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="main-search-input-btn">
                        <button className="button theme-btn" type="submit">Search</button>
                    </div>

                </div>
            </>
        )
    }
}
