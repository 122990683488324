import React from 'react'
import HeaderStyle from './../../../../assets/css/Header.module.css'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'

export default function ThirdStep(props) {

    const handleButton = e => {
        e.preventDefault();
    }

    return (
        <div>

            <div className={HeaderStyle.successWrapper}>
                <IoMdCheckmarkCircleOutline />
                <h1> Activated ! </h1>
                <p> Your account has been activated successfully ! </p>
                <p>You're ready to go.</p>
            </div>
            
            <div className={HeaderStyle.buttonsWrapper}>
                <button type="submit" onClick={handleButton.bind(this)}> Close </button>
            </div>

        </div>
    )
}
