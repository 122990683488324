import React, { useState } from 'react'
import { FiPlus, FiPlusCircle, FiBookmark,FiEdit2 } from 'react-icons/fi';
import { BsListCheck, BsQuestion, BsGear, BsPower } from 'react-icons/bs'
import { AiOutlineUser } from 'react-icons/ai'
import {Link} from "react-router-dom";
import Button from "../../common/Button";
import userimg from '../../../assets/images/team2.jpg'
import { authenticationService } from './../../../auth/loginAuth'

export default function HeaderAuthorAccess(props) {
    const [AuthorAccessOpen, setAuthorAccessOpen] = useState(false)
    // const [loginBox, setLoginBox] = useState(false)
    // const [loginPage, setLoginPage] = useState("login");

    const logoRightStyle = {
        "line-height": "50px"
    }

    return (
        <>
            <div className="logo-right-content" style={logoRightStyle}>
                <ul className="author-access-list">
                { !props.loginStatus &&
                    <li>
                        <Link to="#" onClick={props.openBox.bind(this)} page="login" >login</Link>
                        <span className="or-text">or</span>
                        <Link to="#" onClick={props.openBox.bind(this)} page="signup" >Sign up</Link>
                    </li> }
                
                { props.loginStatus &&
                <>
                    <li>
                        <Button text="add listing" url="/add-listing" >
                            <FiPlusCircle />
                        </Button>
                    </li>
                </>
                }    
                </ul>

                { props.loginStatus &&
                    <div className="side-user-menu-open" onClick={() => setAuthorAccessOpen(!AuthorAccessOpen)}>
                        <AiOutlineUser />
                    </div>
                }
                
            </div>

            {/* Side User panel */}
            { props.loginStatus &&
            <div className={AuthorAccessOpen ? 'side-user-panel active' : 'side-user-panel'}>
                <div className="humburger-menu">
                    <div className="humburger-menu-lines side-menu-close" onClick={() => setAuthorAccessOpen(!AuthorAccessOpen)}></div>
                </div>
                <div className="side-menu-wrap side-user-menu-wrap">

                    <div className="side-user-img">
                        <img src={ (props.loginStatus.avatar_url.length > 0 ? props.loginStatus.avatar_url : userimg )} alt="User" />
                        <h4 className="su__name">{props.loginStatus.username}</h4>
                        <span className="su__meta">{props.loginStatus.email}</span>
                    </div>

                    <ul className="side-menu-ul">
                        <li><Link to="/dashboard"><AiOutlineUser className="user-icon" /> My Profile</Link></li>
                        <li><Link to="/dashboard"><BsListCheck className="user-icon" /> My Listings</Link></li>
                        <li><Link to="/dashboard"><FiBookmark className="user-icon" /> My Bookmarks</Link></li>
                        <li><Link to="/dashboard"><FiPlusCircle className="user-icon" /> add listing</Link></li>
                        <li><div className="dropdown-divider"></div></li>
                        <li><Link to="#"><BsQuestion className="user-icon" /> help</Link></li>
                        <li><Link to="#"><BsGear className="user-icon" /> Settings</Link></li>
                        <li><Link to="#" onClick={authenticationService.logout.bind(this)} ><BsPower className="user-icon" /> Sign Out</Link></li>
                    </ul>
                </div>
            </div>
            }
        </>
    )
}
