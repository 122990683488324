import React from 'react'

export default function GoogleIcon() {
    return (
        <svg id="google" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.622 25.622">
            <path id="Path_131" data-name="Path 131" d="M278.417,211.48H267.967a.835.835,0,0,0-.836.836v3.339a.836.836,0,0,0,.836.836h5.885a7.857,7.857,0,0,1-3.382,3.963l2.509,4.344a12.562,12.562,0,0,0,6.405-10.986,8.466,8.466,0,0,0-.144-1.641A.839.839,0,0,0,278.417,211.48Z" transform="translate(-253.763 -200.896)" fill="#167ee6"/>
            <path id="Path_132" data-name="Path 132" d="M45.5,337.749a7.8,7.8,0,0,1-6.745-3.9l-4.344,2.5a12.8,12.8,0,0,0,17.494,4.7v-.006L49.4,336.7A7.74,7.74,0,0,1,45.5,337.749Z" transform="translate(-32.689 -317.14)" fill="#12b347"/>
            <path id="Path_133" data-name="Path 133" d="M262.405,395.129v-.006l-2.51-4.344a7.74,7.74,0,0,1-3.9,1.053v5.013A12.789,12.789,0,0,0,262.405,395.129Z" transform="translate(-243.189 -371.223)" fill="#0f993e"/>
            <path id="Path_134" data-name="Path 134" d="M5.013,134.519a7.74,7.74,0,0,1,1.053-3.9l-4.344-2.5a12.752,12.752,0,0,0,0,12.8l4.344-2.5A7.74,7.74,0,0,1,5.013,134.519Z" transform="translate(0 -121.708)" fill="#ffd500"/>
            <path id="Path_135" data-name="Path 135" d="M45.5,5.013A7.762,7.762,0,0,1,50.451,6.79a.832.832,0,0,0,1.12-.05l2.366-2.366a.842.842,0,0,0-.048-1.231A12.783,12.783,0,0,0,34.411,6.411l4.344,2.5A7.8,7.8,0,0,1,45.5,5.013Z" transform="translate(-32.689)" fill="#ff4b26"/>
            <path id="Path_136" data-name="Path 136" d="M260.951,6.79a.832.832,0,0,0,1.12-.05l2.366-2.366a.842.842,0,0,0-.048-1.231A12.772,12.772,0,0,0,256,0V5.013A7.762,7.762,0,0,1,260.951,6.79Z" transform="translate(-243.189)" fill="#d93f21"/>
        </svg>
    )
}
