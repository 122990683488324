import React, { useState } from 'react'
import GoogleIcon from '../../../assets/icons/GoogleIcon'
import FacebookIcon from './../../../assets/icons/FacebookIcon'
import HeaderStyle from './../../../assets/css/Header.module.css'
import { HiOutlineLockClosed, HiOutlineUser } from 'react-icons/hi'
import ErrorMessage from './ErrorMessage'
import FormValidations from './FormValidations'
import api from './../../../api'
// import Authentication from './../../../auth/Authetication'
import { authenticationService } from './../../../auth/loginAuth'
import { BiBadgeCheck, BiSync } from 'react-icons/bi'

export default function LoginForm(props) {

    const [errorMessage, manageError] = useState(false)
    const [userEmail, setEmail] = useState('')
    const [userPassword, setPassword] = useState('')
    const [buttonDisabled, disableButton] = useState(false);
    const [inputDisabled, disableInputs] = useState(false);
    const [loginSuccess, setSuccess] = useState(false);

    const addError = message => {
        manageError(message)
    }

    const removeError = () => {
        manageError(false)
    }

    const handleEmail = e => {
        setEmail(e.target.value)
        removeError()
    }

    const handlePassword = e => {
        setPassword(e.target.value)
        removeError()
    }

    const loginHandle = async (data) => {        
        if ( data.code === "200" )
        {
            setSuccess(true);
            try {
                await authenticationService.approveLogin(data);
                props.setLogin(authenticationService.currentUserValue)
            } catch (err) {
                console.log(err)
            }
            
        }else{
            addError(data.message);
        }
    }

    const handleFormAction = async e => {
        e.preventDefault();
        disableButton(true);
        disableInputs(true);

        // Check Email / Phone
        if ( !FormValidations.verifyPhone(userEmail) && !FormValidations.verifyEmail(userEmail) )
        {
            addError("Please enter a valid email or a valid phone number");
        } else if ( !FormValidations.verifyPassword(userPassword) )
        {
            addError("Please enter a correct password");
        }else{
            const infos = {
                email: userEmail,
                password: userPassword
            }

            try {
                await api.userLogin(infos).then( data => loginHandle(data) );
            } catch (error)
            {
                console.log(error);  
            }

        }

        disableInputs(false);
        disableButton(false);
    }

    const loginFormBody = () => {
        return (
            <>
                <div className={HeaderStyle.socialLogin}>
                        <a href="#" className={HeaderStyle.google}> <GoogleIcon /> Login with Google </a>
                        <a href="#" className={HeaderStyle.facebook}> <FacebookIcon /> Login with Facebook </a>
                    </div>
    
                    <form action="">
                        
                        { errorMessage && <ErrorMessage message={errorMessage} /> }
    
                        <div className={HeaderStyle.inputsWrapper}>
                            <label className={HeaderStyle.withIcon}>
                                <span className={HeaderStyle.description}> Email/Phone </span>                        
                                <input type="text" disabled={inputDisabled} placeholder="Write your Email or Phone number" onChange={handleEmail.bind(this)} value={userEmail} />
                                <HiOutlineUser />
                            </label>
                        </div>
    
                        <div className={HeaderStyle.inputsWrapper}>
                            <label className={HeaderStyle.withIcon}>
                                <span className={HeaderStyle.description}> Password </span>                        
                                <input type="password" disabled={inputDisabled} placeholder="Write your Password" onChange={handlePassword.bind(this)} value={userPassword} />
                                <HiOutlineLockClosed />
                            </label>
                        </div>
    
                        <div className={HeaderStyle.forgotContainer}>
                            <a href="#" onClick={props.openRecoveryForm.bind(this)}> Forgot your password ? </a>
                        </div>
    
                        <div className={HeaderStyle.buttonsWrapper}>
                            <button type="submit" onClick={handleFormAction.bind(this)} disabled={buttonDisabled}>
                                { buttonDisabled ? <span>Please wait...</span> : "Login" }
                            </button>
                        </div>
                    </form>
    
                    <div className={HeaderStyle.switchContainer}>
                        <h5> You are new to Nwary ? </h5>
                        <a href="#" onClick={props.openSignupForm.bind(this)}> Create a free account </a>
                    </div>
            </>
        )
    }

    return (
            <div id={HeaderStyle.loginContainer}>
                { !loginSuccess && loginFormBody() }
                { loginSuccess &&
                    <div className={HeaderStyle.loginSuccess}>
                    <BiBadgeCheck className={HeaderStyle.successIcon} />
                    <h1>Welcome</h1>                    
                    <span> <BiSync /> Please wait, the page will refresh automatically...</span>
                </div>
                }
            </div>
    )
}
