import React, { useState } from 'react'
import HeaderStyle from './../../../../assets/css/Header.module.css'
import { HiOutlineLockClosed, HiOutlineUser, HiOutlineMail } from 'react-icons/hi'
import { HiArrowNarrowLeft } from 'react-icons/hi'
import FormValidations from './../FormValidations'
import { FaSpinner } from 'react-icons/fa'
import ErrorMessage from './../ErrorMessage'
import api from './../../../../api'

export default function FirstStep(props) {
    const [inputDisabled, disableInputs] = useState(false);    

    const addError = message => {
        props.manageError(message)
    }

    const [buttonDisabled, disableButton] = useState(false);

    const handleSignup = async e => {
        e.preventDefault();
        disableInputs(true);
        disableButton(true);

        if (   !FormValidations.verifyRequired(props.state.userName)   )
        {
            addError("Please enter your full name");
        } else if (  !FormValidations.verifyEmail(props.state.userEmail)  )
        {
            addError("Please enter a valid email");
        } else if (  !FormValidations.verifyPassword(props.state.userPassword)   )
        {
            addError("The password must be at least 6 letters/numbers ");
        }else if ( props.state.userPassword !== props.state.userPasswordConfirmation )
        {
            addError("Your password and its confirmation don't match ");
        }else {
            const infos = {
                username: props.state.userName,
                email: props.state.userEmail,
                password: props.state.userPassword
            }
    
            try {
                await api.addUser(infos).then( (data) => {
                    if ( data.code === "200" )
                    {
                        const nextStep = props.step+1;
                        props.stepHandler(nextStep)
                    }else{
                        addError(data.message);
                    }
                } );
            } catch(error) {
                console.log(error)
            }
        }

        disableInputs(false);
        disableButton(false);
    }

    return (
        <div>
            <div className={HeaderStyle.linksWrapper}>
                <a href="#" onClick={props.openLoginForm.bind(this)}> <HiArrowNarrowLeft /> Back to login </a>
            </div>

            { props.errorMessage && <ErrorMessage message={props.errorMessage} /> }
            
            <div className={HeaderStyle.inputsWrapper}>
                    <label className={HeaderStyle.withIcon}>
                        <span className={HeaderStyle.description}> Full name </span>                        
                        <input type="text" disabled={inputDisabled} onChange={props.handleInputs.bind(this)} name="userName" placeholder="Write your name" value={props.state.userName} />
                        <HiOutlineUser />
                    </label>
                </div>

                <div className={HeaderStyle.inputsWrapper}>
                    <label className={HeaderStyle.withIcon}>
                        <span className={HeaderStyle.description}> Email </span>                        
                        <input type="text" disabled={inputDisabled} onChange={props.handleInputs.bind(this)} name="userEmail" placeholder="Write your email" value={props.state.userEmail} />
                        <HiOutlineMail />
                    </label>
                </div>

                <div className={HeaderStyle.inputsWrapper}>
                    <label className={HeaderStyle.withIcon}>
                        <span className={HeaderStyle.description}> Password </span>                        
                        <input type="password" disabled={inputDisabled} onChange={props.handleInputs.bind(this)} name="userPassword" placeholder="Write your Password" value={props.state.userPassword} />
                        <HiOutlineLockClosed />
                    </label>
                </div>

                <div className={HeaderStyle.inputsWrapper}>
                    <label className={HeaderStyle.withIcon}>
                        <span className={HeaderStyle.description}> Password confirmation </span>                        
                        <input type="password" disabled={inputDisabled} onChange={props.handleInputs.bind(this)} name="userPasswordConfirmation" placeholder="Re-write your Password" value={props.state.userPasswordConfirmation} />
                        <HiOutlineLockClosed />
                    </label>
                </div>

                <div className={HeaderStyle.buttonsWrapper}>
                    <button type="submit" disabled={buttonDisabled} onClick={handleSignup.bind(this)}>
                        { buttonDisabled ? <span><FaSpinner className={HeaderStyle.spinner} /> &nbsp; Please wait...</span> : "Signup"}
                    </button>
                </div>
        </div>
    )
}
