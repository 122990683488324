import React, {useEffect, useState} from 'react'
import HeaderStyle from './../../assets/css/Header.module.css'
import LoginForm from './loginSignup/LoginForm'
import SignupForm from './loginSignup/SignupForm'
import RecoveryForm from './loginSignup/RecoveryForm'
import { HiX } from 'react-icons/hi'
import LoadingState from './states/LoadingState'

// import { CSSTransition, TransitionGroup } from 'react-transition-group';

export default function LoginBox(props) {

    const [boxLoading, setLoader] = useState(true);

    const [loginOpened, openLogin] = useState(true);
    const [signupOpened, openSignup] = useState(false);
    const [recoveryOpened, openRecovery] = useState(false);   


    // Open login form
    const openLoginForm = async () => {
        setLoader(true);

        try {
            openSignup(false);
            openRecovery(false);
            await openLogin(true);
        }
        catch(error)
        {
            console.log(error)
        }
        setLoader(false);
    }

    // Open signup form
    const openSignupForm = async () => {
        setLoader(true);
        try {
            openLogin(false);
            openRecovery(false);
            await openSignup(true);
        }
        catch(error)
        {
            console.log(error)
        }
        setLoader(false);
    }

    // Open recovery form
    const openRecoveryForm = async () => {
        setLoader(true);
        try {
            openLogin(false);
            openSignup(false);
            await openRecovery(true);
        }
        catch(error)
        {
            console.log(error)
        }
        setLoader(false);
    }

     useEffect( () => {
        if ( props.loginPage === "signup" )
        {
            openLogin(false)
            openSignup(true)
        }

        setLoader(false);
     }, []);

    let isLoading = boxLoading ? HeaderStyle.loading : '';

    const boxClasses = `${HeaderStyle.boxWrapper} ${isLoading}`;

    return (

        <div className={HeaderStyle.modalContainer}>
            <div className={HeaderStyle.loginBox}>
                <div className={HeaderStyle.loginHeader}>
                    <svg className={HeaderStyle.headerIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.405 83.866"><g id="lock" transform="translate(-74.667)"><path id="Path_430" data-name="Path 430" d="M140.047,0a22.742,22.742,0,0,0-22.714,22.714V36.691a1.747,1.747,0,0,0,1.747,1.747h6.989a1.747,1.747,0,0,0,1.747-1.747V22.714a12.23,12.23,0,1,1,24.461,0V36.691a1.747,1.747,0,0,0,1.747,1.747h6.989a1.747,1.747,0,0,0,1.747-1.747V22.714A22.742,22.742,0,0,0,140.047,0Z" transform="translate(-35.677)" fill="#fff"/><path id="Path_431" data-name="Path 431" d="M83.4,213.333h41.933a8.736,8.736,0,0,1,8.736,8.736v31.45a8.736,8.736,0,0,1-8.736,8.736H83.4a8.736,8.736,0,0,1-8.736-8.736v-31.45A8.736,8.736,0,0,1,83.4,213.333Z" transform="translate(0 -178.389)" fill="#ffc107"/><path id="Path_432" data-name="Path 432" d="M220.139,285.881a8.736,8.736,0,1,0-12.611,7.792l-1.349,9.435a1.747,1.747,0,0,0,1.73,1.992H214.9a1.747,1.747,0,0,0,1.747-2L215.3,293.67A8.767,8.767,0,0,0,220.139,285.881Z" transform="translate(-107.033 -231.718)" fill="#455a64"/></g></svg>
                    <a href="#" className={HeaderStyle.closeBox} onClick={props.closeBox.bind(this)}> <HiX /> Close </a>
                </div>

                <div className={boxClasses}>                
                    { boxLoading && <LoadingState />}
                    
                    { loginOpened && <LoginForm openSignupForm={openSignupForm} openRecoveryForm={openRecoveryForm} /> }
                    { signupOpened && <SignupForm openLoginForm={openLoginForm} /> }
                    { recoveryOpened && <RecoveryForm openLoginForm={openLoginForm} /> }

                </div>
            </div>
        </div>
    )
}
