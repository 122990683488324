import React from 'react'
import HeaderStyle from './../../../../assets/css/Header.module.css'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'

export default function FourthStep(props) {
    return (
        <div>

            <div className={HeaderStyle.successWrapper}>
                <IoMdCheckmarkCircleOutline />
                <h1> Done ! </h1>
                <p> Your password has been changed successfully ! </p>
            </div>
            
            <div className={HeaderStyle.buttonsWrapper}>
                <button type="submit" onClick={props.openLoginForm.bind(this)}> Login page </button>
            </div>

        </div>
    )
}
