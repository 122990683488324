import React, { useEffect } from 'react'
import { FaSpinner } from 'react-icons/fa'
import { HiOutlineLockClosed, HiOutlineUser, HiOutlineMail, HiArrowNarrowLeft } from 'react-icons/hi'
import HeaderStyle from './../../../../assets/css/Header.module.css'
import ErrorMessage from './../ErrorMessage'
import InputMask from 'react-input-mask'
import api from './../../../../api'

export default function SecondStep(props) {
    
    useEffect( () => {
        props.removeError()
    }, [])

    const submitCode = async e => {
        e.preventDefault();
        props.disableButton(true);
        props.disableInput(true);

        if ( props.state.userCode.length < 6 )
        {
            props.addError("Please enter a valid code");            
        }else{
            let dataToSend = { email: props.state.userEmail, recovery_code: props.state.userCode }

            try {
                await api.recoveryCode(dataToSend).then( data => {
                    if ( data.code === "200" )
                    {
                        props.nextStep();
                    }else{
                        props.addError(data.message);
                    }
                } )
            }catch(error) {
                console.log(error);
            }
        }
        props.disableButton(false);
        props.disableInput(false);
    }

    return (
        <div>
            
            { !props.errorMessage && <p className={HeaderStyle.notice}> You have received a <u>confirmation code</u> on your email. </p>}
            { props.errorMessage && <ErrorMessage message={props.errorMessage} /> }

                <div className={HeaderStyle.inputsWrapper}>
                    <label className={HeaderStyle.withIcon}>
                        <span className={HeaderStyle.description}> Email </span>                        
                        <input type="text" name="userEmail" placeholder="Write your email" value={props.state.userEmail} onChange={props.handleChange.bind(this)} disabled={true} />
                        <HiOutlineMail />
                    </label>
                </div>

                <div className={HeaderStyle.inputsWrapper}>
                    <label className={`${HeaderStyle.withIcon} ${HeaderStyle.code}`}>
                            <span className={HeaderStyle.description}> Code </span>                        
                            {/* <input type="text" name="userCode" placeholder="Enter confirmation code" value={props.state.userCode} onChange={props.handleChange.bind(this)} autoFocus="true" /> */}
                            <InputMask mask="999999" maskChar="_" name="userCode" alwaysShowMask={true} value={props.state.userCode} onChange={props.handleChange.bind(this)} disabled={props.inputDisabled} />
                            <HiOutlineLockClosed />
                    </label>
                </div>

                <div className={HeaderStyle.buttonsWrapper}>
                    <button type="submit" onClick={submitCode.bind(this)} disabled={props.buttonDisabled}>
                        { !props.buttonDisabled && "Confirm code"}
                        { props.buttonDisabled && <span><FaSpinner className={HeaderStyle.spinner} /> &nbsp; Please wait...</span> }
                    </button>
                <br />
                    <button type="button" onClick={props.previousStep.bind(this)} disabled={props.buttonDisabled}>
                        Change email
                    </button>
                </div>
        </div>
    )
}
