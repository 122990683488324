import { BehaviorSubject } from 'rxjs';
import api from './../api'

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('USERAUTH')));

const approveLogin = async (userData) => {
    const currentTime = new Date();

    try {
        await api.getUserById(userData.user_id)
        .then ( resp => {
            if ( resp.code == "200" )
            {
                const informations = {
                    user_id: userData.user_id,
                    sid: userData.sid,
                    token: userData.token,
                    lastVerified: currentTime,
                    username: resp.message.username,
                    avatar_url: resp.message.avatar_url,
                    creation_date: resp.message.creation_date,
                    email: resp.message.email,
                    status: resp.message.status,
                    country_id: resp.message.country_id,
                    city_id: resp.message.city_id
                }

                localStorage.setItem("USERAUTH", JSON.stringify(informations))
                currentUserSubject.next(informations);
                setTimeout( () => { window.location.reload() }, 3000 )
            }
        } )
    }
    catch (err) 
    {
        console.log(err)
    }
}

const logout = async (e) => {
    e.preventDefault()

    try {
        await api.logout().then( response => {
            if ( response.code == "200" )
            {
                localStorage.removeItem('USERAUTH')
                currentUserSubject.next(false)
                window.location.reload()
            }else{
                console.log(response)
            }
        } )
    }catch(e){
        console.log(e)
    }
}

export const authenticationService = {
    approveLogin,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};