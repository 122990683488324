import React, { useEffect, useState } from 'react'
import HeaderStyle from './../../../assets/css/Header.module.css'
import FirstStep from './signupSteps/FirstStep'
import SecondStep from './signupSteps/SecondStep'
import ThirdStep from './signupSteps/ThirdStep'

export default function SignupForm(props) {

    const [signupStep, changeStep] = useState(1);
    const [errorMessage, manageError] = useState(false)

    const [state, setState] = useState({
        userName: '',
        userEmail: '',
        userPassword: '',
        userPasswordConfirmation: '',
        confirmationCode: ''
    });

    const handleInputs = e => {
        let inputName = e.target.name;
        let inputValue = e.target.value;

        setState({
            ...state,
            [inputName]: inputValue
        })
    }

    const removeError = () => {
        manageError(false)
    }

    //useEffect( () => console.log("hey") ,[]);

    return (
        <div id={HeaderStyle.loginContainer}>

            <form action="">

                { signupStep == 1 &&  <FirstStep manageError={manageError} errorMessage={errorMessage} openLoginForm={props.openLoginForm.bind(this)} state={state} step={signupStep} stepHandler={changeStep} handleInputs={handleInputs} />  }
                { signupStep == 2 &&  <SecondStep manageError={manageError} errorMessage={errorMessage} state={state} step={signupStep} stepHandler={changeStep} handleInputs={handleInputs} />  }
                { signupStep == 3 &&  <ThirdStep />  }
                
            </form>

            {/* <div className={HeaderStyle.switchContainer}>
                <h5> Alreay have an account ? </h5>
                <a href="#"> Login </a>
            </div> */}
        </div>
    )
}
