import React from 'react'
import { FaSpinner } from 'react-icons/fa'
import { HiOutlineLockClosed, HiOutlineUser, HiOutlineMail, HiArrowNarrowLeft } from 'react-icons/hi'
import HeaderStyle from './../../../../assets/css/Header.module.css'
import ErrorMessage from './../ErrorMessage'
import FormValidations from './../FormValidations'
import api from './../../../../api'

export default function FirstStep(props) {
    
    const submitEmail = async e => {
        e.preventDefault();
        props.disableButton(true);
        props.disableInput(true);

        if ( !FormValidations.verifyEmail(props.state.userEmail) )
        {
            props.addError("Please enter a valid email");            
        }else{
            let dataToSend = { email: props.state.userEmail }
            try {
                await api.recoveryEmail(dataToSend).then( data => {
                    if ( data.code === "200" )
                    {
                        props.nextStep();
                    }else{
                        props.addError(data.message);
                    }
                } )
            } catch(error) {
                console.log(error)
            }
        }
        props.disableButton(false);
        props.disableInput(false);
    }

    return (        
        <div>
        { props.errorMessage && <ErrorMessage message={props.errorMessage} /> }
        
            <div className={HeaderStyle.inputsWrapper}>
                    <label className={HeaderStyle.withIcon}>
                        <span className={HeaderStyle.description}> Email </span>                        
                        <input type="text" name="userEmail" placeholder="Write your email" value={props.state.userEmail} onChange={props.handleChange.bind(this)} disabled={props.inputDisabled} />
                        <HiOutlineMail />
                    </label>
                </div>

                <div className={HeaderStyle.buttonsWrapper}>
                    <button type="submit" onClick={submitEmail.bind(this)} disabled={props.buttonDisabled}>
                        { !props.buttonDisabled && "Recover password"}
                        { props.buttonDisabled && <span><FaSpinner className={HeaderStyle.spinner} /> &nbsp; Please wait...</span> }
                    </button>
                </div>
        </div>
    )
}
