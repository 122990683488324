export const categoriesList = [
    {
      name: "Motors",
      subs: [
        {
          name: "motorcycles",
          subs: [
            {
              name: "Type",
              subs: [
                { name: "Used motorcycle" },
                { name: "New motorcycle" }
              ]
            },
            {
              name: "Motorcycle type",
              subs: [
                { name: "Sport bike" },
                { name: "Adventure / Touring" },
                { name: "Scooter" },
                { name: "Off-Road" },
                { name: "Karting" },
                { name: "Trike" },
                { name: "Trailer" },
                { name: "Other" },
              ]
            },
          ]
        },
        {
          name: "Auto accessories & parts",
          subs: [
            { name: "Car/4x4 Parts" },
            { name: "Apparel, Merchandise & Accessories" },
            { name: "Motorcycle Parts" },
            { name: "Automotive Tools" },
            { name: "Boat Parts" },
            { name: "Number Plates" },
            { name: "Other Vehicle Parts" }
          ]
        },
        {
          name: "Boats",
          subs: [
            { name: "Motorboats" },
            { name: "Sailboats" },
            { name: "Row/Paddle Boats" }
          ]
        },
        {
          name: "Heavy Vehicules",
          subs: [
            { name: "Trucks" },
            { name: "Buses" },
            { name: "Forklifts" },
            { name: "Trailers" },
            { name: "Cranes" },
            { name: "Tankers" },
            { name: "Parts & Engines" },
            { name: "Other Heavy Vehicles" }
          ]
        },
        {
          name: "Cars",
          subs: [
            {
              name: "Type",
              subs: [
                { name: "Used cars" },
                { name: "New cars" }
              ]
            },
            {
              name: "Brand",
              subs: [
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/abarth1.png",
                  name: "Abarth"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/ac-cars.png",
                  name: "AC"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/acura.png",
                  name: "Acura"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/aixam.png",
                  name: "Aixam"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/alfa_romeo.png",
                  name: "Alfa Romeo"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/ariel.png",
                  name: "Ariel"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/10/arrinera-logo.png",
                  name: "Arrinera"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/aston_martin.png",
                  name: "Aston Martin"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/audi.png",
                  name: "Audi"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/bentley.png",
                  name: "Bentley"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/bmw.png",
                  name: "BMW"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/bugatti.png",
                  name: "Bugatti"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/buick.png",
                  name: "Buick"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/cadillac.png",
                  name: "Cadillac"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/caterham.png",
                  name: "Caterham"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/chevrolet.png",
                  name: "Chevrolet"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/chrysler.png",
                  name: "Chrysler"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/citroen.png",
                  name: "Citroën"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/corvette.png",
                  name: "Corvette"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/dacia.png",
                  name: "Dacia"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/daf.png",
                  name: "Daf"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/daihat.png",
                  name: "Daihatsu"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/dodge.png",
                  name: "Dodge"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/10/drmotor.png",
                  name: "DR Motor"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/elfin.png",
                  name: "Elfin"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/ferrari.png",
                  name: "Ferrari"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/fiat.png",
                  name: "Fiat"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/ford.png",
                  name: "Ford"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/gaz.png",
                  name: "Gaz"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/geely.png",
                  name: "Geely"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/gillet.png",
                  name: "Gillet"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/ginetta.png",
                  name: "Ginetta"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/gm.png",
                  name: "General Motors"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/gmc.png",
                  name: "GMC"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2013/10/Great-Wall.png",
                  name: "Great Wall"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/gumpert.png",
                  name: "Gumpert"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/10/hennessey.png",
                  name: "Hennessey logo"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/10/holden.png",
                  name: "Holden"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/honda.png",
                  name: "Honda"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/hummer.png",
                  name: "Hummer"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/hyundai.png",
                  name: "Hyundai"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/inf.png",
                  name: "Infiniti"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/isuzu.png",
                  name: "Isuzu"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/jagu.png",
                  name: "Jaguar"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/jeep.png",
                  name: "Jeep"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/joss.png",
                  name: "Joss"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/kia.png",
                  name: "Kia"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/koenigsegg.png",
                  name: "Koenigsegg"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/lada.png",
                  name: "Lada"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/lamb.png",
                  name: "Lamborghini"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/lancia.png",
                  name: "Lancia"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/land-rover.png",
                  name: "Land Rover"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/lexus.png",
                  name: "Lexus"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/linc.png",
                  name: "Lincoln"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/lotus.png",
                  name: "Lotus"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/11/luxgen-logo.png",
                  name: "Luxgen"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/11/mahindra.png",
                  name: "Mahindra"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/12/Maruti_Suzuki.png",
                  name: "Maruti Suzuki"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/mase.png",
                  name: "Maserati"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/maybach.png",
                  name: "Maybach"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/mazda.png",
                  name: "Mazda"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/mclaren.png",
                  name: "Mclaren"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/marchedrs.png",
                  name: "Mercedes"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/mg.png",
                  name: "MG"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/mini.png",
                  name: "Mini"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/mitub.png",
                  name: "Mitsubishi"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/morgan.png",
                  name: "Morgan Motor"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/mustang.png",
                  name: "Mustang logo"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/nissan.png",
                  name: "Nissan"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/noble.png",
                  name: "Noble"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/opel.png",
                  name: "Opel"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/pagani.png",
                  name: "Pagani"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/panoz.png",
                  name: "Panoz"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/perodua.png",
                  name: "Perodua"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/peug.png",
                  name: "Peugeot"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/piaggio.png",
                  name: "Piaggio"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/pininfarina.png",
                  name: "Pininfarina"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/porsche.png",
                  name: "Porsche"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/proton.png",
                  name: "Proton"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/renault.png",
                  name: "Renault"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/reva.png",
                  name: "Reva"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2012/01/rimac-150x150.png",
                  name: "Rimac"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/rolls-royce.png",
                  name: "Rolls Royce"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/ruf.png",
                  name: "Ruf logo"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/saab.png",
                  name: "Saab"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/scania.png",
                  name: "Scania"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/scion.png",
                  name: "Scion"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/seat.png",
                  name: "Seat"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/10/shelby.png",
                  name: "Shelby"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/skoda.png",
                  name: "Skoda"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/smart.png",
                  name: "Smart"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/spyker.png",
                  name: "Spyker"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/ssangyong.png",
                  name: "Ssangyong"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/10/ssc.png",
                  name: "SSC"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/subaru.png",
                  name: "Subaru"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/suzuki.png",
                  name: "Suzuki"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/tata.png",
                  name: "Tata"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/tatra.png",
                  name: "Tatra"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/tesla.png",
                  name: "Tesla"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/toyota.png",
                  name: "Toyota"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/1176359_187686584745478_1792749640_n.jpg",
                  name: "Tramontana"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/troller.png",
                  name: "Troller"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/tvr.png",
                  name: "TVR"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/uaz.png",
                  name: "UAZ"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/vandenbrink_design.png",
                  name: "Vandenbrink"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/vauxhall.png",
                  name: "Vauxhall"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/vector_motors.png",
                  name: "Vector Motors"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/venturi.png",
                  name: "Venturi"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/volkswagen.png",
                  name: "Volkswagen"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/volvo.png",
                  name: "Volvo"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/wiesmann.png",
                  name: "Wiesmann"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/zagato.png",
                  name: "Zagato"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/zaz.png",
                  name: "Zaz"
                },
                {
                  logo: "https://www.car-logos.org/wp-content/uploads/2011/09/zil.png",
                  name: "Zil"
                }
              ]
            },
          ]
        },
      ]
    },
    {
      name: "Real estate",
      subs: [
        {
          name: "Property for sale",
          subs: [
            { name: "Apartment for sale" },
            { name: "Villa/house for sale" },
            { name: "Commercial for sale" },
            { name: "Land for sale" }
          ]
        },
        {
          name: "Property for rent",
          subs: [
            { name: "Apartment for rent" },
            { name: "Villa/house for rent" },
            { name: "Commercial for rent" },
            { name: "Land for rent" }
          ]
        }
      ]
    },
    {
      name: "Insurance",
      subs: [
        { name: "Car Insurance" },
        { name: "Home Insurance" },
        { name: "Life Insurance" },
        { name: "Disability Insurance" },
        { name: "Health Insurance" },
        { name: "Liability Insurance" },        
      ]
    },
    {
      name: "Jobs",
      subs: [
        {
          name: "Type",
          subs: [
            { name: "Job offers (recruiters) " },
            { name: "Looking for job" }
          ]
        },
        {
          name: "Category",
          subs: [
            { name: "Accounting" },
            { name: "Architecture & Interior design" },
            { name: "Automotive" },
            { name: "Beauty" },
            { name: "Business Development" },
            { name: "Construction" },
            { name: "Customer Service" },
            { name: "Education" },
            { name: "Engineering" },
            { name: "Fashion" },
            { name: "Food & Baverages" },
            { name: "Graphic Design" },
            { name: "Hospitality & Restaurants" },
            { name: "HR & Recruitment" },
            { name: "Industrial & Manufacturing" },
            { name: "Information Technology" },
            { name: "Internet" },
            { name: "Legal Services" },
            { name: "Logistics & Distribution" },
            { name: "Marketing & Advertising" },
            { name: "Media" },
            { name: "Medical & Healthcare" },
            { name: "Online Media" },
            { name: "Real Estate" },
            { name: "Retail & Consumer Goods" },
            { name: "Safety & Security" },
            { name: "Sales" },
            { name: "Secretarial" },
            { name: "Sports & Fitness" },
            { name: "Transportation" },
            { name: "Travel & Tourism" },
          ]
        }
      ]
    },
    {
      name: "Electronics",
      subs: [
        { name: "Home Audio & Turntables" },
        { name: "Televisions" },
        { name: "DVD & Home Theater" },
        { name: "Electronic Accessories" },
        { name: "Gadgets" },
        { name: "Car Electronics" },
        { name: "Mp3 Players and Portable Audio" },
        { name: "Satellite & Cable TV" },
        { name: "Health Electronics" },
        { name: "Smart Home" },
        { name: "Wearable Technology" },
        { name: "Other" }
      ]
    },
    {      
      name: "Computers & Networking",
      subs: [
        { name: "Computers" },
        { name: "Computer Components" },
        { name: "Accessories" },
        { name: "Networking & Communication" },
        { name: "Software" },
        { name: "Monitors, Printers & Other Peripherals" },
        { name: "Other" }
      ]
    },
    {      
      name: "Business & Industrial",
      subs: [
        { name: "Businesses for Sale" },
        { name: "Construction" },
        { name: "Food & Beverage" },
        { name: "Industrial Supplies" },
        { name: "Office Furniture & Equipment" },
        { name: "Manufacturing" },
        { name: "Electrical Equipment" },
        { name: "Retail & Services" },
        { name: "Healthcare & Lab" },
        { name: "Commercial Printing & Copy Machines" },
        { name: "Packing & Shipping" },
        { name: "Agriculture & Forestry" },
        { name: "Other" }
      ]
    },
    {
      name: "Home Appliance",
      subs: [
        { name: "Large Appliances / White Goods" },
        { name: "Small Kitchen Appliances" },
        { name: "Outdoor Appliances" },
        { name: "Small Bathroom Appliances" },
        { name: "Irons & Sewing Machines" },
        { name: "Vaccums & Floor Care" },
        { name: "Other" }
      ]
    },
    {
      name: "Sports Equipment",
      subs: [
        { name: "Cycling" },
        { name: "Exercise Equipment" },
        { name: "Water Sports" },
        { name: "Camping & Hiking" },
        { name: "Golf" },
        { name: "Indoor Sports" },
        { name: "Team Sports" },
        { name: "Tennis & Racquet Sports" },
        { name: "Winter Sports" },
        { name: "Other Sports" }
      ]
    },
    {
      name: "Clothing & Accessories",
      subs: [
        { name: "Shoes/Footwear" },
        { name: "Clothing" },
        { name: "Handbags, Bags & Wallets" },
        { name: "Men's Accessories" },
        { name: "Women's Accessories" },
        { name: "Luggage" },
        { name: "Fragrances" },
        { name: "Wedding Apparel" },
        { name: "Costumes & Uniforms" },
        { name: "Vintage & Highend Clothing" },
        { name: "Make up & Skin Care" }
      ]
    },
    {
      name: "Cameras & Imaging",
      subs: [
        { name: "Digital Cameras" },
        { name: "Lenses, Filters & Lighting" },
        { name: "Professional Equipment" },
        { name: "Camera Accessories" },
        { name: "Tripods & Stands" },
        { name: "Camcorders" },
        { name: "Film Cameras" },
        { name: "Binoculars/Telescopes" },
        { name: "Camcorder Accessories" },
        { name: "Camera Drones" }
      ]
    },
    {
      name: "Jewelry & Watches",
      subs: [
        { name: "Watches" },
        { name: "Women's Jewelry" },
        { name: "Men's Jewelry" },
        { name: "Loose Diamonds & Gems" },
        { name: "Other" }
      ]
    },
    {
      name: "Pets",
      subs: [
        { name: "Pets for Free Adoption" },
        { name: "Pet Accessories" },
        { name: "Lost & Found Pets" }
      ]
    },
    {
      name: "Musical Instruments",
      subs: [
        { name: "Guitars" },
        { name: "Pianos, Keyboards & Organs" },
        { name: "Percussion" },
        { name: "String Instruments" },
        { name: "Wind Instruments" },
        { name: "DJ & Recording Equipment" },
        { name: "Other" }
      ]
    },
    {
      name: "Gaming",
      subs: [
        { name: "Gaming Systems" },
        { name: "Video Games" },
        { name: "Gaming Accessories" }
      ]
    },
    {
      name: "Baby items",
      subs: [
        { name: "Strollers & Car Seats" },
        { name: "Nursery Furniture & Accessories" },
        { name: "Baby Gear" },
        { name: "Baby Toys" },
        { name: "Feeding" },
        { name: "Safety & Health" },
        { name: "Bath & Diapers" }
      ]
    },
    {
      name: "Toys",
      subs: [
        { name: "Electronic & Remote Control Toys" },
        { name: "Action Figures & Toy Vehicles" },
        { name: "Outdoor Toys & Structures" },
        { name: "Hobbies" },
        { name: "Pretend Play & Preschool Toys" },
        { name: "Educational Toys" },
        { name: "Dolls & Stuffed Animals" },
        { name: "Games & Puzzles" },
        { name: "Classic & Vintage Toys" },
        { name: "Building Toys" },
        { name: "Other" }
      ]
    },
    {
      name: "Books",
      subs: [
        { name: "Textbooks" },
        { name: "Nonfiction" },
        { name: "Fiction" },
        { name: "Children's Books" },
        { name: "Book Accessories" },
        { name: "Digital/E-books" },
        { name: "Audiobooks" },
        { name: "Other" }
      ]
    },
    {
      name: "Music",
      subs: [
        { name: "Vinyl" },
        { name: "CDs" },
        { name: "Casettes" },
        { name: "Digital" }
      ]
    },
    {
      name: "DVDs & Movies",
      subs: [
        { name: "DVD" },
        { name: "Digital" },
        { name: "VHS" },
        { name: "Other Formats" }
      ]
    },
    {
      name: "Furniture, Home & Garden",
      subs: [
        { name: "Furniture" },
        { name: "Home Accessories" },
        { name: "Garden & Outdoor" },
        { name: "Lighting & Fans" },
        { name: "Rugs & Carpets" },
        { name: "Curtains & Blinds" },
        { name: "Tools & Home Improvement" }
      ]
    },
    {
      name: "Mobiles & Tablets",
      subs: [
        {
          name: "Mobile Phones",
          subs: [
            {
              name: "Brand",
              subs: [
                { name: "Apple" },
                { name: "Samsung" },
                { name: "Google" },
                { name: "Huawei" },
                { name: "Acer" },
                { name: "Alcatel" },
                { name: "Asus" },
                { name: "Blackberry" },
                { name: "CAT" },
                { name: "CECT" },
                { name: "HTC" },
                { name: "Hewlett Packard" },
                { name: "LG" },
                { name: "Lava" },
                { name: "Lenovo" },
                { name: "Meizu" },
                { name: "Motorola" },
                { name: "Nokia" },
                { name: "OnePlus" },
                { name: "Oppo" },
                { name: "Palm" }
              ]
            }
          ]
        },
        {
          name: "Tablets",
          subs: [
            {
              name: "Brand",
              subs: [
                { name: "Apple" },
                { name: "Dell" },
                { name: "Franklin" },
                { name: "HP/Compaq" },
                { name: "HTC" },
                { name: "Handspring" },
                { name: "Huawei" },
                { name: "Lenovo" },
                { name: "Palm" },
                { name: "RIM" },
                { name: "Samsung" },
                { name: "Sharp" },
                { name: "Sony" },
                { name: "Symbol" },
                { name: "Wintouch" },
                { name: "iMate" }
              ]
            }
          ]
        },
        {
          name: "Mobile phone & Tablets Accessories",
          subs: [
            { name: "Batteries" },
            { name: "Cables & Adapters" },
            { name: "Car Kits" },
            { name: "Cases & Sleeves" },
            { name: "Chargers" },
            { name: "Gimbals & Selfie Sticks" },
            { name: "Headsets" },
            { name: "Other" }
          ]
        }
      ]
    }
  ]