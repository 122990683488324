import React , { Component} from 'react'
import BannerThreeSearchInput3 from './components/banner/banner3/BannerThreeSearchInput3'
import SearchMotors from './components/banner/banner3/SearchMotors'
import PropertySearch from './components/banner/banner3/PropertySearch'
import ElectronicsSearch from './components/banner/banner3/ElectronicsSearch'

export const searchCategories = [
    {
        name: "Motors",
        searchForm: <SearchMotors subs={[
            { value:"All motors", label: "All motors" },
            { value:"motorcycles", label: "motorcycles" },
            { value:"Auto accessories & parts", label: "Auto accessories & parts" },
            { value:"Boats", label: "Boats" },
            { value:"Heavy Vehicules", label: "Heavy Vehicules" },
            { value:"Cars", label: "Cars" }
          ]} />,
      },
      {
        name: "Real estate",
        searchForm: <PropertySearch subs={[
            { value:"Property for sale", label: "Property for sale" },
            { value:"Property for rent", label: "Property for rent" }
          ]} />,
      },
      {
        name: "Electronics",
        searchForm: <ElectronicsSearch subs={[
            { label: "Home Audio & Turntables" },
            { label: "Televisions" },
            { label: "DVD & Home Theater" },
            { label: "Electronic Accessories" },
            { label: "Gadgets" },
            { label: "Car Electronics" },
            { label: "Mp3 Players and Portable Audio" },
            { label: "Satellite & Cable TV" },
            { label: "Health Electronics" },
            { label: "Smart Home" },
            { label: "Wearable Technology" },
            { label: "Other" }
          ]} />
      }
]