// import axios from 'axios';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

//const axios = window.axios;
const BASE_API_URL = "https://nwary.com/Api";

export default {
  //getAllUsers: () => axios.get(`${BASE_API_URL}/searchUser`),
  //getHeaderCategories: () => axios.get(`${BASE_API_URL}/getCategoriesProducts`),
  //editUser: (id,post) => axios.post(`${BASE_API_URL}/editUser/${id}`, post),
  getUserById: (id) => 
  fetch(`${BASE_API_URL}/getUserById/${id}`).then((response) => response.json()),
  
  editUser: (id,post) =>
    fetch(`${BASE_API_URL}/editUser/${id}`, {
      method: 'POST',
      // mode: 'same-origin', // no-cors, *cors, same-origin
      body: JSON.stringify(post),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => response.json()),
  searchUser: () =>
    fetch(`${BASE_API_URL}/searchUser`).then((response) => response.json()),
  
  addUser: (infos) =>
  fetch(`${BASE_API_URL}/addUser`, {
    method: 'POST',
    // mode: 'same-origin', // no-cors, *cors, same-origin
    body: JSON.stringify(infos),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
    .then((response) => response.json()),
  
  userLogin: (infos) => 
  fetch(`${BASE_API_URL}/userLogin`, {
    method: 'POST',
    // mode: 'same-origin', // no-cors, *cors, same-origin
    body: JSON.stringify(infos),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
    .then((response) => response.json()),

  recoveryEmail: (infos) =>
    fetch(`${BASE_API_URL}/recoveryEmail`, {
      method: 'POST',
      // mode: 'same-origin', // no-cors, *cors, same-origin
      body: JSON.stringify(infos),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => response.json()),
  
  recoveryCode: (infos) =>
  fetch(`${BASE_API_URL}/recoveryCode`, {
    method: 'POST',
    // mode: 'same-origin', // no-cors, *cors, same-origin
    body: JSON.stringify(infos),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
    .then((response) => response.json()),
  
  recoveryPassword: (infos) =>
    fetch(`${BASE_API_URL}/recoveryPassword`, {
      method: 'POST',
      // mode: 'same-origin', // no-cors, *cors, same-origin
      body: JSON.stringify(infos),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => response.json()),
  
  verifySession: (infos) =>
  fetch(`${BASE_API_URL}/verifySession`, {
    method: 'POST',
    // mode: 'same-origin', // no-cors, *cors, same-origin
    body: JSON.stringify(infos),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
    .then((response) => response.json()),
  
  logout: (infos) =>
  fetch(`${BASE_API_URL}/logout`, {
    method: 'POST',
    // mode: 'same-origin', // no-cors, *cors, same-origin
    body: JSON.stringify(infos),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
    .then((response) => response.json())
}
