import React, {Component} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { GiPositionMarker } from 'react-icons/gi'
import { IoMdMusicalNotes } from 'react-icons/io'
import { AiOutlineHome } from 'react-icons/ai'
import { FaSearchPlus } from 'react-icons/fa'
import {Link} from "react-router-dom";
// import BannerOneSearchInput from "../banner1/BannerOneSearchInput";
// import BannerThreeSearchInput2 from "./BannerThreeSearchInput2";
// import BannerThreeSearchInput3 from "./BannerThreeSearchInput3";
import SearchAll from "./SearchAll";

import { searchCategories } from './../../../searchCategories'

class Banner3Tab extends Component {
    render() {
        return (
            <>
                <Tabs>
                    <div className="tab-shared">
                        <TabList className="nav nav-tabs" id="myTab">
                            <Tab>
                                <Link className="nav-link theme-btn radius-rounded"  to="#">
                                    All
                                </Link>
                            </Tab>

                            {
                                searchCategories.map( (e,i) => (
                                    <Tab>
                                        <Link className="nav-link theme-btn radius-rounded"  to="#">
                                            {e.name}
                                        </Link>
                                    </Tab>
                                ) )
                            }
                            
                        </TabList>
                    </div>
                    <TabPanel>
                        <SearchAll />
                    </TabPanel>
                    
                    {
                        searchCategories.map( (e,i) => (
                            <TabPanel>
                                {e.searchForm}
                            </TabPanel>
                        ) )
                    }

                    {/* <TabPanel>
                        <BannerOneSearchInput />
                    </TabPanel>
                    <TabPanel>
                        <BannerThreeSearchInput2 />
                    </TabPanel>
                    <TabPanel>
                        <BannerOneSearchInput />
                    </TabPanel>
                    <TabPanel>
                        <BannerThreeSearchInput3 />
                    </TabPanel> */}
                </Tabs>
            </>
        );
    }
}

export default Banner3Tab;