import React, { useState } from 'react'
import HeaderStyle from './../../../../assets/css/Header.module.css'
import { HiOutlineLockClosed, HiOutlineUser, HiOutlineMail } from 'react-icons/hi'
import FormValidations from './../FormValidations'
import { FaSpinner } from 'react-icons/fa'
import ErrorMessage from './../ErrorMessage'
import api from './../../../../api'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'

export default function SecondStep(props) {
    const [inputDisabled, disableInputs] = useState(false);

    const addError = message => {
        props.manageError(message)
    }

    const [buttonDisabled, disableButton] = useState(false);

    const handleActivation = async e => {
        e.preventDefault();
        disableInputs(true);
        disableButton(true);

        if (   !FormValidations.verifyRequired(props.state.confirmationCode)   )
        {
            addError("Please enter the verification code");
        }else {
            const recoveryData = {
                email: props.state.userEmail,
                recovery_code: props.state.confirmationCode
            }
            
            try {
                await api.recoveryCode(recoveryData)
                .then( (result) => {
                    if ( result.code === "200" )
                    {
                        const nextStep = props.step+1;
                        props.stepHandler(nextStep)
                    }else{
                        props.manageError(result.message)
                    }
                } )
            } catch(error)
            {
                console.log(error)
            }
        }

        disableInputs(false);
        disableButton(false);
    }

    return (
        <div>

            <div className={HeaderStyle.successWrapper}>
                <IoMdCheckmarkCircleOutline />
                <h1> Congrats ! </h1>
                <p> Your account has been created successfully ! </p>
                <p>We have sent you an email with a verification code.</p>
            </div>

            { props.errorMessage && <ErrorMessage message={props.errorMessage} /> }
                <div className={HeaderStyle.inputsWrapper}>
                    <label className={HeaderStyle.withIcon}>
                        <span className={HeaderStyle.description}> Confirmation code </span>                        
                        <input type="text" disabled={inputDisabled} onChange={props.handleInputs.bind(this)} name="confirmationCode" placeholder="Enter the confirmation code" value={props.state.confirmationCode} />
                        <HiOutlineLockClosed />
                    </label>
                </div>

                <div className={HeaderStyle.buttonsWrapper}>
                    <button type="submit" disabled={buttonDisabled} onClick={handleActivation.bind(this)}>
                        { buttonDisabled ? <span><FaSpinner className={HeaderStyle.spinner} /> &nbsp; Please wait...</span> : "Activate account"}
                    </button>
                <br />
                    <button type="button" disabled={buttonDisabled}> Confirm later </button>
                </div>
        </div>
    )
}
