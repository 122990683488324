import React, {useEffect} from 'react'
import { FaSpinner } from 'react-icons/fa'
import { HiOutlineLockClosed, HiOutlineUser, HiOutlineMail, HiArrowNarrowLeft } from 'react-icons/hi'
import HeaderStyle from './../../../../assets/css/Header.module.css'
import ErrorMessage from './../ErrorMessage'
import InputMask from 'react-input-mask'
import FormValidations from './../FormValidations'
import api from './../../../../api'

export default function ThirdStep(props) {

    useEffect( () => {
        props.removeError()
    }, [])

    const submitPassword = async e => {
        e.preventDefault();
        props.disableButton(true);
        props.disableInput(true);

        if ( !FormValidations.verifyPassword(props.state.newPassword) )
        {
            props.addError("Please enter a valid code");
        }else if ( props.state.newPassword !== props.state.newPasswordConfirmation ){
            props.addError("The new password and its confirmation don't match");
        }else{
            let dataToSend = { email: props.state.userEmail, recovery_code: props.state.userCode, new_password: props.state.newPassword }
            try {
                await api.recoveryPassword(dataToSend).then( data => {
                    if ( data.code === "200" )
                    {
                        props.nextStep();
                    }else{
                        props.addError(data.message);
                    }
                } )
            } catch(error)
            {
                console.log(error)
            }
        }
        props.disableButton(false);
        props.disableInput(false);
    }

    return (
        <div>

            { props.errorMessage && <ErrorMessage message={props.errorMessage} /> }

                <div className={HeaderStyle.inputsWrapper}>
                    <label className={HeaderStyle.withIcon}>
                        <span className={HeaderStyle.description}> Email </span>                        
                        <input type="text" name="userEmail" placeholder="Write your email" value={props.state.userEmail} onChange={props.handleChange.bind(this)} disabled={true} />
                        <HiOutlineMail />
                    </label>
                </div>

                <div className={HeaderStyle.inputsWrapper}>
                    <label className={`${HeaderStyle.withIcon} ${HeaderStyle.code}`}>
                            <span className={HeaderStyle.description}> Code </span>
                            <InputMask mask="999999" maskChar="_" name="userCode" alwaysShowMask={true} value={props.state.userCode} onChange={props.handleChange.bind(this)} disabled={true} />
                            <HiOutlineLockClosed />
                    </label>
                </div>

                <div className={HeaderStyle.inputsWrapper}>
                    <label className={HeaderStyle.withIcon}>
                        <span className={HeaderStyle.description}> Password </span>                        
                        <input type="password" onChange={props.handleChange.bind(this)} name="newPassword" placeholder="Enter a new password" value={props.state.newPassword} disabled={props.inputDisabled} />
                        <HiOutlineLockClosed />
                    </label>
                </div>

                <div className={HeaderStyle.inputsWrapper}>
                    <label className={HeaderStyle.withIcon}>
                        <span className={HeaderStyle.description}> Password confirmation </span>                        
                        <input type="password" onChange={props.handleChange.bind(this)} name="newPasswordConfirmation" placeholder="Re-write your new Password" value={props.state.newPasswordConfirmation} disabled={props.inputDisabled} />
                        <HiOutlineLockClosed />
                    </label>
                </div>

                <div className={HeaderStyle.buttonsWrapper}>
                    <button type="submit" onClick={submitPassword.bind(this)} disabled={props.buttonDisabled}>
                        { !props.buttonDisabled && "Change password"}
                        { props.buttonDisabled && <span><FaSpinner className={HeaderStyle.spinner} /> &nbsp; Please wait...</span> }
                    </button>
                </div>
        </div>
    )
}
