import React from 'react'
import HeaderStyle from './../../../assets/css/Header.module.css'

export default function LoadingState() {
    return (
        <div className={HeaderStyle.loaderState}>
            <span className={HeaderStyle.dot}></span>
        </div>
    )
}
