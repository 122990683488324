import React, {Component} from 'react';
import  { FiSearch } from 'react-icons/fi'
import  { FaDollarSign } from 'react-icons/fa'
import SelectCountry from "../../common/SelectCountry";
import Select from "react-select";

class PropertySearch extends Component {
    state = {
        selectedPropertyOp: null,
    }

    handleChangeProperty = () => {
        const { selectedPropertyOp } = this.state;
        this.setState(
            { selectedPropertyOp }
        );
    }
    render() {
        return (
            <>
                <div className="main-search-input">
                    <div className="main-search-input-item">
                        <div className="contact-form-action">
                            <form action="#">
                                <div className="form-group mb-0">
                                    <span className="form-icon">
                                        <FiSearch />
                                    </span>
                                    <input className="form-control" type="text" placeholder="What are you looking for?" />
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="main-search-input-item category">
                        <Select
                            value={this.selectedPropertyOp}
                            onChange={this.handleChangeProperty}
                            placeholder="Property type"
                            options={this.props.subs}
                        />
                    </div>
                    <div className="main-search-input-btn">
                        <button className="button theme-btn" type="submit">Search</button>
                    </div>
                </div>
            </>
        );
    }
}

export default PropertySearch;