import React from 'react';
import { Switch, Route } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/style.css';
import './App.css';
import Home3 from './pages/homes/Home3';
import AllCategories from './pages/categories/AllCategories';
import AllLocations from './pages/categories/AllLocations';
import TopPlaces from "./pages/categories/TopPlaces";
import ListingGrid from "./pages/listings/ListingGrid";
import ListingList from "./pages/listings/ListingList";
import ListMapView from "./pages/listings/ListMapView";
import ListMapView2 from "./pages/listings/ListMapView2";
import ListLeftSidebar from "./pages/listings/ListLeftSidebar";
import ListRightSidebar from "./pages/listings/ListRightSidebar";
import ListingDetails from "./pages/listings/ListingDetails";
import AddListing from "./pages/listings/AddListing";
import UserProfile from "./components/other/account/UserProfile";
import TopAuthors from "./pages/TopAuthors";
import Dashboard from "./pages/dashboard/Dashboard";
// import Booking from "./pages/dashboard/Booking";
import BookingConfirmation from "./pages/dashboard/BookingConfirmation";
// import Invoice from "./pages/dashboard/Invoice";
// import PricingPlan from "./pages/PricingPlan";
import About from "./pages/About";
import Faq from "./pages/FAQ";
import Contact from "./pages/Contact";
import BlogFullWidth from "./pages/blogs/BlogFullWidth";
import BlogDetail from "./pages/blogs/BlogDetail";
import Error from "./pages/Error";
import { useState } from 'react';

import GeneralHeader from "./components/common/GeneralHeader"
import Footer from "./components/common/footer/Footer"
// import {privateComponent} from './auth/privateComponent'
import { authenticationService } from './auth/loginAuth'

import { categoriesList } from './categories'
import { mainCategoriesList } from './mainCategories'

function App() {

  const [disabledContainer, toggleDisable] = useState(false);
  const [loginStatus, setLogin] = useState(authenticationService.currentUserValue);

  // categoriesList.map( (e,i) => {
  //   console.log(e.name)
  // } )

  return (
    <div className={"mainContainer" + (disabledContainer && " disabled" )}>
    <GeneralHeader toggleDisable={toggleDisable} mainCategoriesList={mainCategoriesList} categoriesList={categoriesList} loginStatus={loginStatus} setLogin={setLogin} />

      <Route>
        <Switch>
          <Route exact path="/" component={Home3} />
          <Route path="/all-categories" component={AllCategories} />
          <Route path="/all-locations" component={AllLocations} />
          <Route path="/top-place" component={TopPlaces} />
          <Route path="/listing-grid" component={ListingGrid} />
          <Route path="/listing-list" component={ListingList} />
          <Route path="/list-map-view" component={ListMapView} />
          <Route path="/list-map-view2" component={ListMapView2} />
          <Route path="/list-left-sidebar" component={ListLeftSidebar} />
          <Route path="/list-right-sidebar" component={ListRightSidebar} />
          <Route path="/listing-details" component={ListingDetails} />
          <Route path="/add-listing" component={AddListing} />
          <Route path="/user-profile" component={UserProfile} />
          <Route path="/top-author" component={TopAuthors} />
          <Route path="/dashboard" component={Dashboard} />
          {/* <Route path="/booking" component={Booking} /> */}
          <Route path="/booking-confirmation" component={BookingConfirmation} />
          {/* <Route path="/invoice" component={Invoice} /> */}
          {/* <Route path="/pricing" component={PricingPlan} /> */}
          {/* <Route path="/about" component={About} /> */}
          <Route path="/faq" component={Faq} />
          <Route path="/contact" component={Contact} />
          <Route path="/blog-full-width" component={BlogFullWidth} />
          <Route path="/blog-single" component={BlogDetail} />
          <Route component={Error} />
        </Switch>
      </Route>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default App;
