import React from 'react'

export default function FacebookIcon() {
    return (
        <svg id="facebook" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.811 25.811">
            <path id="Path_138" data-name="Path 138" d="M22.585,0H3.226A3.23,3.23,0,0,0,0,3.226V22.585a3.23,3.23,0,0,0,3.226,3.226H22.585a3.23,3.23,0,0,0,3.226-3.226V3.226A3.23,3.23,0,0,0,22.585,0Z" fill="#1976d2"/>
            <path id="Path_139" data-name="Path 139" d="M204.1,104.066h-4.033V100.84c0-.89.723-.807,1.613-.807h1.613V96h-3.226a4.839,4.839,0,0,0-4.84,4.84v3.226H192V108.1h3.226v8.873h4.84V108.1h2.42Z" transform="translate(-182.321 -91.16)" fill="#fafafa"/>
        </svg>
    )
}
